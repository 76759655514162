import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { heroImages } from '../../assets/images';

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const HeroContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${heroImages.background});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.primary};
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 2rem;
  transform: translateY(25%);
  margin-top: 15vh;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled.button`
  background-color: ${colors.accent};
  color: ${colors.primary};
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #cc0000;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const Hero = () => {
  return (
    <PageWrapper>
      <HeroContainer>
        <HeroContent>
          {/* <Title>Discover Your Next Adventure</Title>
          <Subtitle>Experience unforgettable journeys with Sandy Travel Tours</Subtitle> */}
          <CTAButton as="a" href="/contact">Start Planning Today</CTAButton>
        </HeroContent>
      </HeroContainer>
    </PageWrapper>
  );
};

export default Hero;
